import React, { lazy } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { AdminLayoutRoute } from "./../../routes";

import Header from "../Admin/Header/Header";

class AdminLayout extends React.Component {
  render() {
    return (
      <div className="main-page">
        <Header {...this.props} />
        <Routes>
          {AdminLayoutRoute.map((route, index) => {
            return (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                element={route.component}
              />
            );
          })}
        </Routes>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
