import { lazy } from "react";
import AdminLayout from "./layout/Admin/index";


//Admin
const AdminLogin = lazy(() => import("./pages/Admin/Login/Login"));
const ForgotPassword = lazy(() =>
  import("./pages/Admin/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./pages/Admin/ResetPassword/ResetPassword")
);
const AddConstituency = lazy(() =>
  import("./pages/Admin/AddConstituency/AddConstituency")
);
const PollListing = lazy(() => import("./pages/Admin/PollListing/PollListing"));
const OngoingPollDetails = lazy(() =>
  import("./pages/Admin/OngoingPollDetails/OngoingPollDetails")
);
const UpcomingPollDetails = lazy(() =>
  import("./pages/Admin/UpcomingPollDetails/UpcomingPollDetails")
);
const PastPollDetails = lazy(() =>
  import("./pages/Admin/PastPollDetails/PastPollDetails")
);
const LocalAuthority = lazy(() =>
  import("./pages/Admin/LocalAuthority/LocalAuthority")
);
const AddLocalAuthority = lazy(() =>
  import("./pages/Admin/AddLocalAuthority/AddLocalAuthority")
);
const AlterNEC = lazy(() => import("./pages/Admin/AlterNEC/ALterNEC"));
const NECList = lazy(() => import("./pages/Admin/NECList/NECList"));
const CreatePoll = lazy(() => import("./pages/Admin/CreatePoll/CreatePoll"));

// Nec User
const Login = lazy(() => import("./pages/User/Login/Login"));
const Voting = lazy(() => import("./pages/User/Voting/Voting"));

const PageNotFound = lazy(() => import("./pages/PageNotFound/PageNotFound"));

const routes = [
  {
    path: "/",
    component: <Login />,
    authenticate: false,
    exact: true,
  },
  {
    path: "/admin",
    component: <AdminLogin />,
    authenticate: false,
    exact: true,
  },
  {
    path: "/admin/forgotpassword",
    component: <ForgotPassword />,
    authenticate: false,
    exact: true,
  },
  {
    path: "/admin/resetpassword",
    component: <ResetPassword />,
    authenticate: false,
    exact: true,
  },

  // End User routes with header
  // {
  //   path: "/*",
  //   component: <UserLayout />,
  //   authenticate: false,
  //   exact: true
  // },
  {
    path: "/voting",
    component: <Voting />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/admin/*",
    component: <AdminLayout />,
    authenticate: false,
    exact: true,
  },
];

export const AdminLayoutRoute = [
  {
    path: "/addconstituency",
    component: <AddConstituency />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/polllisting",
    component: <PollListing />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/localauthority",
    component: <LocalAuthority />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/addlocalauthority",
    component: <AddLocalAuthority />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/editlocalauthority",
    component: <AddLocalAuthority />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/alternec",
    component: <AlterNEC />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/neclist/:id",
    component: <NECList />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/createpoll",
    component: <CreatePoll />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/ongoing",
    component: <OngoingPollDetails />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/upcoming",
    component: <UpcomingPollDetails />,
    exact: true,
    authenticate: false,
  },
  {
    path: "/past",
    component: <PastPollDetails />,
    exact: true,
    authenticate: false,
  },
];

export default routes;
