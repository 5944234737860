import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import routes from "./routes";
import { connect, useSelector } from "react-redux";
import ToastComponent from "./components/UI/ToastComponent/ToastComponent";
import { hideToast } from "../src/redux/actions/toastAction";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "./components/UI/Loading/Loading";
import "./App.scss";

function App(props) {
  const state = useSelector((state) => state);
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          //from merchant supported languages
          en: require("./translations/english.json"),
        },
      })
    );
  }, []);

  window.onbeforeunload = (e) => {
    props.hideToast();
  };

  return (
    <div className="App">
      <Suspense fallback={<h1></h1>}>
        <ToastComponent />
        {/* <Loader show={true} /> */}
        <Loader show={state?.mainLoaderReducer?.showLoader} />
        <BrowserRouter>
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  element={route.component}
                />
              );
            })}
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  hideToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(App));
