import { SHOW_LOADER, HIDE_LOADER } from "../actions/loaderActions";

const initState = {
  showLoader: false,
};

const mainLoaderReducer = (state = initState, action) => {
  if (action.type === SHOW_LOADER) {
    return {
      ...state,
      showLoader: true,
    };
  }
  if (action.type === HIDE_LOADER) {
    return {
      ...state,
      showLoader: false,
    };
  }

  return state;
};

export default mainLoaderReducer;
