import React from "react";
import './Label.scss';

const Label = (props) => {
    let { title } = props;
    return (
        <p className={'labelTitle ' + props.className} onClick={props.onClick}>{title}{props.required && <label>*</label>}</p>
    )
}

Label.defaultProps = {
    className: '',
}
export default Label;