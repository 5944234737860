import moment from 'moment';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import momentTz from 'moment-timezone';

export function isCheckValueAndSetParams(params, value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }
  return params + value;
}

export const downloadFile = (link) => {
  fetch(link).then((response) => {
    let filename = response.url.split('/');
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename[filename.length - 1];
      a.click();
    });
  });
};
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
export const uploadFIle = async (file) => {
  var formdata = new FormData();
  formdata.append('file', file);
  formdata.append('cloud_name', process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
  formdata.append(
    'upload_preset',
    process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
  );

  let res = await fetch('https://api.cloudinary.com/v1_1/zudu/upload', {
    method: 'post',
    mode: 'cors',
    body: formdata,
  });

  let json = await res.json();
  let imageURL = json.secure_url.split('/');
  return imageURL[imageURL.length - 1];
};

export const extractTime = (data) => {
  if (data === null) {
    return false;
  }
  const time = moment(data).format('h:mm a');
  return time;
};

export const extractTimeFullFormate = (data) => {
  if (data === null) {
    return false;
  }
  const time = moment(data).format('HH');
  return time;
};

export const endDateWithStartTimeFilter = (data) => {
  if (data === null) {
    return false;
  }

  const dateFormated = moment(data).format('YYYY-MM-DDT23:59:59.000') + 'Z';
  // const date = new Date(dateFormated).toISOString();
  return dateFormated;
};

export const extractDateISO = (data) => {
  if (data === null) {
    return false;
  }

  const dateFormated = moment(data).format('YYYY-MM-DDT00:00:00.000') + 'Z';
  // const date = new Date(dateFormated).toISOString();
  return dateFormated;
};

export const extractDateForPicker = (data) => {
  if (data === null) {
    return false;
  }

  const date = new Date(moment(data).format('MM/DD/YYYY'));
  return date;
};

export const timeAndDateConvertToIso = (time, data) => {
  if (data === null && time === null) {
    return false;
  }

  const date = moment(data).format('MM/DD/YYYY');

  const formatedDate = moment(date + ' ' + time).format(
    'YYYY/MM/DD hh:mm:ss a'
  );

  return formatedDate;
};

export const dateAndTimeConvert = (time, data) => {
  if (data === null && time === null) {
    return false;
  }
  const date = moment(data).format('MM/DD/YYYY');

  const formateDate = moment(date + ' ' + time).format('DD MMM hh:mm a');

  return formateDate;
};

export const dateAndTimeConvertOnPollEdit = (time, data) => {
  if (data === null && time === null) {
    return false;
  }
  const date = moment(data).format('MM/DD/YYYY');

  const formateDate = moment(date + ' ' + time).format();

  return formateDate;
};

export const singleDateConvertDMT = (data) => {
  if (data === null) {
    return false;
  }

  const formateDate = moment(data).format('DD MMM hh:mm a');

  return formateDate;
};

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseHTML = (string) => {
  if (string !== undefined && isJsonString(string)) {
    const result = JSON.parse(string);
    let data = parse(draftToHtml(result));
    return data;
  } else {
    return string;
  }
};

export const getNextDay = (data) => {
  if (data === null) {
    return false;
  }
  var date = new Date(data);

  // Add a day
  date.setDate(date.getDate() + 1);
  const dateNew = extractDateISO(date);
  return dateNew;
};

export const formateDateForMail = () => {
  const date = moment().format('DD MMM YYYY h:mm a');
  return date;
};

export const utcDateCombine = (date, time) => {
  if (date === null && time === null) {
    return false;
  }
  const dateOnly = moment(date).format('MM/DD/YYYY');
  const timeOnly = moment(time).format('HH:mm:ss');
  const combine = moment(dateOnly + ' ' + timeOnly).format();
  const returnDateTime = new Date(combine).toISOString();
  return returnDateTime;
};

export const convertTimeToZone = (utcDate) => {
  if (utcDate === null) {
    return false;
  }

  const formatDate = momentTz(moment(utcDate));
  const currentTimeZone = moment.tz.guess();
  const timeZone = formatDate
    .clone()
    .tz(currentTimeZone)
    .format('YYYY/MM/DD hh:mm:ss a');

  return timeZone;
};

export const dayMonthYearShow = (days) => {
  if (days === null) {
    return false;
  }
  let data = '';
  if (days <= 7) {
    data = checkDays(days);
  } else if (days >= 7 && days < 30) {
    data = `${checkDays(days)} ${
      parseInt(days % 7) > 0 ? checkDays(days % 7) : ''
    } `;
  } else if (days >= 30 && days < 365) {
    data = `${checkDays(days)}
    ${parseInt(days % 30) > 0 ? checkDays(days % 30) : ''}
     ${
       parseInt(days % 7) > 0 && parseInt(days % 30) === 0
         ? checkDays(days % 7)
         : ''
     } `;
  } else {
    data = `${checkDays(days)}
    ${parseInt(days % 365) > 0 ? checkDays(days % 365) : ''}
    ${
      parseInt(days % 30) > 0 && parseInt(days % 365) === 0
        ? checkDays(days % 30)
        : ''
    }
    ${
      parseInt(days % 7) > 0 &&
      parseInt(days % 365) === 0 &&
      parseInt(days % 30) === 0
        ? checkDays(days % 7)
        : ''
    }`;
  }
  return data;
};

const checkDays = (days) => {
  let data = '';

  if (days <= 7) {
    data = `0${parseInt(days)} ${days > 1 ? 'days' : 'day'}`;
  } else if (days >= 7 && days < 30) {
    data = `0${parseInt(days / 7)} ${
      parseInt(days / 7) > 1 ? 'weeks' : 'week'
    }`;
  } else if (days >= 30 && days < 365) {
    data = `${
      parseInt(days / 30) > 9 ? parseInt(days / 30) : '0' + parseInt(days / 30)
    } ${parseInt(days / 30) > 1 ? 'months' : 'month'}`;
  } else {
    data = `${
      parseInt(days / 365) > 9
        ? parseInt(days / 365)
        : '0' + parseInt(days / 365)
    } ${parseInt(days / 365) > 1 ? 'Years' : 'Year'}`;
  }

  return data;
};
