import React from "react";
import { Form } from "react-bootstrap";
import "./TextField.scss";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputMask from "react-input-mask";

const TextField = (props) => {
  let {
    errors,
    placeholder,
    multiErrorFields,
    disabled,
    type,
    textarea,
    name,
    autoFocus,
    handleFocus,
    handleBlur,
    iconClass,
    maxLength,
    onIconClick,
    onKeyDown,
    formMethod,
    rows,
    defaultValue,
    rules,
    errorIcon,
    successIcon,
    readOnly,
    required,
    noTextfield,
    maskType,
    valueProp,
    iconName,
  } = props;

  let values = formMethod?.getValues();
  let hasError = errors[name] !== undefined;

  return (
    <div className={required ? "textField" : "notRequired textField"}>
      {noTextfield ? (
        // <></>
        <>
        <Controller
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask={maskType}
              placeholder={placeholder}
              className="form-control"
              onChange={(e) => {
                props.onChange(e.target.value);
                onChange(e.target.value);
              }}
              // maxLength={maxLength}
              disabled={disabled}
              maskChar=""
              value={valueProp}
            />
            
          )}
          name={name}
          control={formMethod?.control}
          rules={rules}
        />
        {iconClass && (
          <div className="iconSection">
            <i
              className={"icon-css " + iconClass}
              onClick={() => onIconClick()}
            ></i>
          </div>
        )}
        {props.errorIconSingle && iconName && hasError && (
          <i className={`icon-` + iconName} />
        )}
        </>
        
      ) : (
        <>
          <Controller
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
              <>
                <Form.Control
                  className={hasError ? "error" : ""}
                  name={name}
                  placeholder={placeholder}
                  autoFocus={autoFocus}
                  disabled={disabled}
                  autoComplete="off"
                  required={required}
                  type={type}
                  as={textarea}
                  readOnly={readOnly}
                  rows={rows}
                  onBlur={() => handleBlur}
                  onKeyDown={onKeyDown}
                  maxLength={maxLength}
                  onFocus={(e) => {
                    handleFocus && handleFocus(e);
                  }}
                  onChange={(e) => {
                    if (props.inputType && props.inputType === "postalCode") {
                      onChange &&
                        onChange(e.target.value.trimLeft().toUpperCase());
                      props.onChange && props.onChange(e);
                    } else {
                      onChange && onChange(e.target.value.trimLeft());
                      props.onChange && props.onChange(e);
                    }
                  }}
                  value={formMethod.watch(name) || value ? value : ""}
                />
              </>
            )}
            name={name}
            control={formMethod?.control}
            rules={rules}
          />
          {/* {!formMethod?.watch(name) && <label> {placeholder}</label>} */}
          {iconClass && (
            <div className="iconSection">
              <i
                className={"icon-css " + iconClass}
                onClick={() => onIconClick()}
              ></i>
            </div>
          )}
          {props.errorIconSingle && iconName && hasError && (
            <i className={`icon-` + iconName} />
          )}
        </>
      )}

      {multiErrorFields.length > 0 ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ messages }) => {
            if (messages) {
              let isMultipleError = Object.keys(messages).every(
                (errKey) =>
                  multiErrorFields.filter((m) => m[errKey] !== undefined)
                    .length > 0
              );
              if (isMultipleError) {
                let arr = [];
                for (const fieldError of multiErrorFields) {
                  let key = Object.keys(fieldError)[0];
                  let success = Object.keys(messages).includes(key);
                  arr.push(
                    <div className={success ? "red" : "green"} key={key}>
                      <span key={key}>
                        <i className={success ? errorIcon : successIcon} />
                        {fieldError[key]}
                      </span>
                    </div>
                  );
                }
                return (
                  <div className="errorMsg 2 show passwordcustom">{arr}</div>
                );
              } else {
                return (
                  <div className="errorMsg 1 show">
                    <i className={errorIcon} />
                    {errors[name]?.message}
                  </div>
                );
              }
            } else {
              return <div className="errorMsg 3"></div>;
            }
          }}
        />
      ) : hasError ? (
        <div className="errorMsg 4 show">
          <span>
            <i className={errorIcon} />
            {errors[name]?.message}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

TextField.defaultProps = {
  autoFocus: false,
  value: "",
  errors: {},
  multiErrorFields: [],
  noNegative: false,
  errorIcon: "icon-error_outline",
  successIcon: "icon-checkmark",
  readOnly: false,
  required: true,
  noTextfield: false,
};

export default TextField;
