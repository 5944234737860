import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Offcanvas, Button } from "react-bootstrap";
import { showToast } from "../../../redux/actions/toastAction";
import Scrollbars from "react-custom-scrollbars";
import { useLocation } from "react-router-dom";
import Constant from "../../../utils/constant";
import { Controller } from "react-hook-form";
import useWindowDimension from "../../../hooks/useWindowDimension";
import ConfirmModal from "../../../components/UI/ConfirmModal/ConfirmModal";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import HookForm from "../../../components/HookForm/HookForm";
import TextField from "../../../components/UI/TextField/TextField";
import Label from "../../../components/UI/Label/Label";
import FileUpload from "../../../components/UI/FileUpload/FileUpload";
import {
  getItem,
  removeItem,
  setItem,
} from "../../../services/localstorage.service";
import { uploadFIle } from "../../../services/helper.service";

import "./Header.scss";
import axios from "axios";
import { destroyToken } from "../../../services/jwt.service";
import AxiosInstance from "../../../config/axios";
import { act } from "react-dom/test-utils";

const Header = (props) => {
  let { strings } = props;
  let history = useNavigate();
  const dimensions = useWindowDimension();
  const user = getItem("ys_user_admin");
  const profileImageUrl = user
    ? process.env.REACT_APP_CLOUDINARY_URL + user?.profileImageUrl
    : "";
  const [showlogoutModal, setShowlogoutModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarClose = () => {
    setSidebarOpen(false);
    setChangePasswordPart(false);
    setEditProfile(false);
  };
  const sidebarShow = () => setSidebarOpen((s) => !s);

  const [changePasswordForm, setChangePasswordForm] = useState();
  const [editProfileForm, setEditProfileForm] = useState();
  const [showOldPass, setOldShowPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [busy, setBusy] = useState(false);

  const [changePasswordPart, setChangePasswordPart] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const { pathname } = useLocation();
  let [showImageName, setShowImageName] = useState();
  let [showImage, setShowImage] = useState();
  let [profile, setProfile] = useState();

  let activeMenu = "";

  if (
    pathname.indexOf("/admin/polllisting") === 0 ||
    pathname.indexOf("/admin/createpoll") === 0 ||
    pathname.indexOf("/admin/ongoing") === 0 ||
    pathname.indexOf("/admin/upcoming") === 0 ||
    pathname.indexOf("/admin/past") === 0
  ) {
    activeMenu = "polllisting";
  } else if (
    pathname.indexOf("/admin/localauthority") === 0 ||
    pathname.indexOf("/admin/addlocalauthority") === 0 ||
    pathname.indexOf("/admin/editlocalauthority") === 0
  ) {
    activeMenu = "localauthority";
  } else if (
    pathname.indexOf("/admin/alternec") === 0 ||
    pathname.indexOf("/admin/neclist") === 0
  ) {
    activeMenu = "alternec";
  }

  useEffect(() => {
    setHeaderHeight(document.getElementsByClassName("navbar")[0]?.offsetHeight);
  });

  const changePassword = {
    oldpassword: {
      name: "oldpassword",
      validate: {
        required: {
          value: true,
          message: strings["OLD_PASSWORD_REQUIRED"],
        },
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: strings["NEW_PASSWORD_REQUIRED"],
        },
        validate: {
          hasUpperLowerCase: (value) =>
            value && value.match(Constant.REGEX.LOWERCASEUPPERCASE) !== null,
          hasNumbers: (value) =>
            (value && value.match(Constant.REGEX.NUMBER)) !== null,
          hasSpecialChar: (value) =>
            (value && value.match(Constant.REGEX.SPECIALCHARACTERS)) !== null,
          length: (value) => (value && value.length >= 8) || "",
        },
      },
    },
    confirmPassword: {
      name: "confirmPassword",
      validate: {
        required: {
          value: true,
          message: strings["CONFIRMPASSWORDREQUIRED"],
        },
      },
    },
  };

  const formEditProfile = {
    fullName: {
      name: "fullName",
      validate: {
        required: {
          value: true,
          message: strings["FULL_NAME_REQUIRED"],
        },
        pattern: {
          value: Constant.REGEX.NAME,
          message: strings["INVALID_NAME"],
        },
      },
    },
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };

  const multiErrorFields = [
    { length: strings["PASSWORD_ERROR"] },
    { hasSpecialChar: strings["1_SPECIAL_CHARACTER"] },
    { hasNumbers: strings["1_NUMBER"] },
    { hasUpperLowerCase: strings["UPPERLOWERCASE"] },
  ];
  const onPressLeft = () => {
    setShowlogoutModal(false);
    history("/admin");
    removeItem("ys_user_admin");
    // props.showToast({
    //   message: "Logout Successful!",
    //   type: "success",
    // });
  };

  const onPressChangePassword = () => {
    setChangePasswordPart(true);
    setEditProfile(false);
  };

  const onPressCancelPassword = () => {
    setChangePasswordPart(false);
  };

  const onPressEdit = () => {
    setEditProfile(true);
  };

  const onFormSubmit = (data) => {
    const param = {
      oldPassword: data.oldpassword,
      newPassword: data.confirmPassword,
    };
    setBusy(true);
    AxiosInstance.put("/auth/changepassword", param)
      .then((res) => {
        props.showToast({
          message: res?.message,
          type: "success",
        });
        setBusy(false);
        setChangePasswordPart(false);
      })
      .catch((error) => {
        props.showToast({
          message: error?.response?.data?.message,
          type: "error",
        });
        setBusy(false);
      });
  };
  const onEditFormSubmit = async (data) => {
    setBusy(true);
    const body = {
      name: data.fullName,
    };
    if (showImage) {
      body.profileImageUrl = await uploadFIle(showImage);
    }
    AxiosInstance.put("auth/updateprofile", body)
      .then((res) => {
        setItem("ys_user_admin", res.payload.user);
        props.showToast({
          message: res?.message,
          type: "success",
        });
        setBusy(false);
        setChangePasswordPart(false);
        setEditProfile(false);
        setProfile();
      })
      .catch((error) => {
        props.showToast({
          message: error?.response?.data?.message,
          type: "error",
        });
        setBusy(false);
      });
    props.showToast({
      message: "Profile Updated!",
      type: "success",
    });
  };

  const onPressCancelEdit = (data) => {
    setEditProfile(false);
  };

  const onImageChange = async (acceptedFiles) => {
    setShowImageName(acceptedFiles[0].name);
    setShowImage(acceptedFiles[0]);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setProfile(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };

  return (
    <>
      <Navbar expand="md">
        <Navbar.Brand href="#">
          <img
            src={Constant.IMAGESURL.LOGO_SMALL}
            className="logo"
            alt=""
            title=""
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="/admin/polllisting"
              className={activeMenu === "polllisting" ? "activeLink" : ""}
            >
              {strings["POLL_LISTING"]}
            </Nav.Link>
            <Nav.Link
              href="/admin/localauthority"
              className={activeMenu === "localauthority" ? "activeLink" : ""}
            >
              {strings["LOCAL_AUTHORITY"]}
            </Nav.Link>
            <Nav.Link
              href="/admin/alternec"
              className={activeMenu === "alternec" ? "activeLink" : ""}
            >
              {strings["ALTERNATE_NEC"]}
            </Nav.Link>
            <Nav.Link
              href="https://young.scot/privacy-policy "
              target='_blank'
            // className={activeMenu === "alternec" ? "activeLink" : ""}
            >
              {strings["PRIVACY_POLICY"]}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <div className="userDetails">
          <p className="userName">{user?.name}</p>
          <p className="userEmail">{user?.email}</p>
        </div>

        <Button className="userButton" onClick={sidebarShow}>
          <div className="userButtonImage">
            <div className="userAvtar">
              {user?.profileImageUrl ? (
                <img src={profileImageUrl} alt="logo" />
              ) : (
                <p>
                  {user?.name?.split(" ")[0]?.charAt(0) +
                    user?.name.split(" ")[1]?.charAt(0)}
                </p>
              )}
            </div>
          </div>
        </Button>
      </Navbar>

      <Offcanvas
        show={sidebarOpen}
        onHide={sidebarClose}
        placement="end"
        backdropClassName="backdrop"
      >
        <i
          className="icon icon-cross"
          onClick={() => {
            setSidebarOpen(false);
          }}
        ></i>
        <p className="profileTitleText">{strings["MY_PROFILE"]}</p>
        <Scrollbars className="scrollbar">
          <div className="alignCenter">
            <div>
              {/* <img
                src="https://res.cloudinary.com/zudu/image/upload/v1646642342/Young-scot/Project-images/dummyProfile.svg"
                className="dummyProfile"
              /> */}

              {editProfile && (
                <div className="editProfile">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setEditProfileForm(form)}
                    onSubmit={(e) => onEditFormSubmit(e)}
                  >
                    {(formMethod) => {
                      return (
                        <>
                          <Label title={strings["EDIT_PROFILE"]} />
                          <Controller
                            defaultValue=""
                            name="image"
                            control={formMethod.control}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <div className="profileRound">
                                  {profile ? (
                                    <div className="uploadedImg">
                                      <img src={profile} alt="" />
                                    </div>
                                  ) : (
                                    <>
                                      <div className="uploadedImg">
                                        {user?.profileImageUrl ? (
                                          <img
                                            src={profileImageUrl}
                                            alt="logo"
                                          />
                                        ) : (
                                          <p>
                                            {user?.name
                                              ?.split(" ")[0]
                                              ?.charAt(0) +
                                              user?.name
                                                .split(" ")[1]
                                                ?.charAt(0)}
                                          </p>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                                {
                                  <FileUpload
                                    onDrop={(acceptedFiles) => {
                                      onChange(acceptedFiles);
                                      onImageChange(acceptedFiles);
                                    }}
                                    accept="image/jpeg,image/jpg,image/png"
                                  >
                                    <span className="editText">
                                      {!busy && strings["CHANGE"]}
                                    </span>
                                  </FileUpload>
                                }
                              </>
                            )}
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={formEditProfile.fullName.validate}
                            name={formEditProfile.fullName.name}
                            errors={formMethod?.formState?.errors}
                            type={"text"}
                            placeholder={strings["FULL_NAME"]}
                            disabled={busy}
                            onChange={(e) => {
                              let value = e.target.value;
                              formMethod.setValue(
                                formEditProfile.fullName.name,
                                e.target.value
                              );
                            }}
                            defaultValue={user?.name}
                          />

                          <TextField
                            formMethod={formMethod}
                            name={formEditProfile.email.name}
                            type={"text"}
                            defaultValue={user?.email}
                            disabled={true}
                          />

                          <div className="buttonInline">
                            <CustomButton
                              className="update"
                              type="submit"
                              title={strings["UPDATE"]}
                              disabled={
                                !formMethod?.formState.isValid ||
                                (user?.name !== formMethod.getValues("fullName")
                                  ? false
                                  : !user?.profileImageUrl || !profile)
                              }
                              loading={busy}
                            />
                            <CustomButton
                              className="cancel"
                              type="button"
                              disabled={busy}
                              title={strings["CANCEL"]}
                              onClick={() => {
                                onPressCancelEdit();
                              }}
                            />
                          </div>
                        </>
                      );
                    }}
                  </HookForm>
                </div>
              )}

              {!editProfile && !changePasswordPart && (
                <div className="profileSection">
                  <div className="userButtonImage">
                    <div className="userAvtar">
                      {user?.profileImageUrl ? (
                        <img src={profileImageUrl} alt="logo" />
                      ) : (
                        <p>
                          {user?.name?.split(" ")[0]?.charAt(0) +
                            user?.name.split(" ")[1]?.charAt(0)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="nameWithDetails">
                    <h6>{user?.name}</h6>
                    <p>{user?.email}</p>
                    <span
                      onClick={() => {
                        onPressEdit();
                      }}
                    >
                      {strings["EDIT"]}
                    </span>
                  </div>
                </div>
              )}

              {changePasswordPart && (
                <div className="changePassword">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setChangePasswordForm(form)}
                    onSubmit={(e) => onFormSubmit(e)}
                  >
                    {(formMethod) => {
                      return (
                        <>
                          <Label title={strings["CHANGE_PASSWORD"]} />
                          <TextField
                            formMethod={formMethod}
                            name={changePassword.oldpassword.name}
                            rules={changePassword.oldpassword.validate}
                            errors={formMethod?.formState?.errors}
                            type={showOldPass ? "text" : "password"}
                            placeholder={strings["OLD_PASSWORD"]}
                            iconClass={
                              showOldPass ? "icon-eye" : "icon-eye-off"
                            }
                            onIconClick={() => setOldShowPass(!showOldPass)}
                            maxLength={16}
                            onChange={(e) => {
                              let value = e.target.value;
                              e.target.value = value.replaceAll(" ", "");
                              formMethod.setValue(
                                changePassword.oldpassword.name,
                                e.target.value
                              );
                            }}
                          />
                          <TextField
                            formMethod={formMethod}
                            multiErrorFields={multiErrorFields}
                            name={changePassword.password.name}
                            rules={changePassword.password.validate}
                            errors={formMethod?.formState?.errors}
                            type={showPass ? "text" : "password"}
                            placeholder={strings["NEW_PASSWORD"]}
                            iconClass={showPass ? "icon-eye" : "icon-eye-off"}
                            onIconClick={() => setShowPass(!showPass)}
                            maxLength={16}
                            onChange={(e) => {
                              let value = e.target.value;
                              e.target.value = value.replaceAll(" ", "");
                              formMethod.setValue(
                                changePassword.password.name,
                                e.target.value
                              );
                              formMethod.watch(
                                changePassword.confirmPassword.name
                              ) &&
                                formMethod.trigger(
                                  changePassword.confirmPassword.name
                                );
                            }}
                          />
                          <TextField
                            formMethod={formMethod}
                            name={changePassword.confirmPassword.name}
                            rules={{
                              required: {
                                value: true,
                                message: strings["CONFIRMPASSWORDREQUIRED"],
                              },
                              validate: {
                                matchPassword: (value) =>
                                  value ===
                                  formMethod.watch(
                                    changePassword.password.name
                                  ) ||
                                  formMethod.watch(
                                    changePassword.password.name
                                  ) === "" ||
                                  strings["CONFIRMINVALID"],
                              },
                            }}
                            errors={formMethod?.formState?.errors}
                            type={showNewPass ? "text" : "password"}
                            placeholder={strings["CONFIRM_PASSWORD"]}
                            iconClass={
                              showNewPass ? "icon-eye" : "icon-eye-off"
                            }
                            onIconClick={() => setShowNewPass(!showNewPass)}
                            maxLength={16}
                            onChange={(e) => {
                              let value = e.target.value;
                              e.target.value = value.replaceAll(" ", "");
                              formMethod.setValue(
                                changePassword.confirmPassword.name,
                                e.target.value
                              );
                            }}
                          />
                          <div className="buttonInline">
                            <CustomButton
                              className="update"
                              type="submit"
                              title={strings["UPDATE"]}
                              disabled={!formMethod?.formState.isValid}
                              loading={busy}
                            />
                            <CustomButton
                              className="cancel"
                              type="button"
                              disabled={busy}
                              title={strings["CANCEL"]}
                              onClick={() => {
                                onPressCancelPassword();
                              }}
                            />
                          </div>
                        </>
                      );
                    }}
                  </HookForm>
                </div>
              )}

              {!changePasswordPart && !editProfile && (
                <p className="changePasswordText">
                  <span
                    onClick={() => {
                      onPressChangePassword();
                    }}
                  >
                    {strings["CHANGE_PASSWORD"]}
                  </span>
                </p>
              )}

              <p
                className={
                  busy === true ? "logOutcenter disabled" : "logOutcenter"
                }
              >
                <span
                  onClick={() => {
                    setShowlogoutModal(true);
                  }}
                >
                  {strings["LOGOUT"]}
                </span>
              </p>
            </div>
          </div>
        </Scrollbars>
      </Offcanvas>

      <ConfirmModal
        showModal={showlogoutModal}
        iconName={"alert-octagon"}
        title={strings["WANT_TO_LOGOUT"]}
        leftBtnTitle={strings["YESLOGOUT"]}
        rightBtnTitle={strings["NOCANCEL"]}
        onPressLeft={() => {
          onPressLeft();
        }}
        onPressRight={() => {
          setShowlogoutModal(false);
        }}
        leftBtnClass="yesLogout"
        rightBtnClass="noCancel"
        className={"confirm-logout"}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  showToast,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(React.memo(Header)));
