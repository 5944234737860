import React, { useState } from "react";
import CustomButton from "../CustomButton/CustomButton";
import { Fade, Modal } from "react-bootstrap";

import "./ConfirmModal.scss";

const ConfirmModal = (props) => {
  const {
    iconName,
    title,
    subTitle,
    leftBtnTitle,
    rightBtnTitle,
    leftBtnClass,
    rightBtnClass,
    onPressLeft,
    onPressRight,
    subTitleDeleteColor,
  } = props;
  let [btnVisible, setVisible] = useState(props.btnVisible);

  return (
    <Modal
      className={"confirmModal " + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => {
        onPressRight();
      }}
      centered
    >
      {iconName && <i className={`icon-` + iconName} />}
      {title && (
        <p className={props.subTitle ? "titleWith" : "title"}>{title}</p>
      )}
      {subTitle && <p className="subTitle">{subTitle}</p>}

      {btnVisible && (
        <div className="modal-footer">
          <CustomButton
            type="submit"
            className={leftBtnClass}
            title={leftBtnTitle}
            loading={props.loader}
            onClick={() => {
              onPressLeft(true);
            }}
          />
          <CustomButton
            type="button"
            className={rightBtnClass}
            title={rightBtnTitle}
            onClick={() => {
              onPressRight(false);
            }}
          />
        </div>
      )}
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  className: "confirmModal",
  btnVisible: true,
};

export default ConfirmModal;
