import React from "react";
import "./Loading.scss";
// import Loader from "../../../assets/images/LoaderIcon.svg";
// import Blinkimage from "../../../assets/images/BlinkImage.svg";
// import giphy from "../../../assets/images/giphy.gif";
import YoungscotLoader from "../../../assets/images/YoungscotLoader.gif";

const Loading = ({ show }) => {
  return show ? (
    <div className="loaderContainer">
      <div className="innerDiv">
        {/* <img src={Loader} title="" alt="" /> */}
        <img className="spinnerGif" src={YoungscotLoader} alt="" />
      </div>
    </div>
  ) : null;
};

Loading.defaultProps = {};

export default Loading;
