import loaderReducer from "./loaderReducer";
import toastReducer from "./toastReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import mainLoaderReducer from "./mainLoaderReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  loading: loaderReducer,
  toastReducer,
  mainLoaderReducer,
});

export default rootReducer;
