import React from 'react';
import Dropzone from 'react-dropzone';

const FileUpload = (props) => {
    let { multiple, onDrop, setRef, accept } = props;
    return (
        <div className="dropzoneCustom">
            <Dropzone accept={props.accept} onDrop={acceptedFiles => { onDrop && onDrop(acceptedFiles); }} ref={setRef}>
                {({ getRootProps, getInputProps }) => {
                    return <div className="txtDropzone" {...getRootProps()}>
                        <input {...getInputProps()} multiple={multiple} accept={accept ? accept : 'image/jpeg,image/jpg, image/png'} />
                        {props.children}
                    </div>
                }}
            </Dropzone>
        </div>
    )
}

export default FileUpload;