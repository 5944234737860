const Constant = {
  REGEX: {
    EMAIL:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    SPECIALCHARACTERS: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    UPPERCASE: /.*[A-Z]|[A-Z]/,
    LOWERCASE: /.*[a-z]|[a-z]/,
    LOWERCASEUPPERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/, // eslint-disable-next-line
    NUMBER: /\d/,
    NAME: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
    NECNUMBER: /[0-9]{13}TMP|[0-9]{16}$/,
    ALLNUMBER: /^\d+$/,
    ALPHANUMERIC: /^[ a-zA-Z0-9\.\-\,]+$/i,
    NUMBER_BETWEEN_1TO9999: /^[1-9][0-9]{0,6}?$|^9999999$/,
    NUMBER_BETWEEN_1TO2000: /^[1-9][0-9]{0,3}?$|^2000$/,
    POSTAL_CODE: /^[A-PR-UWY-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/,
    SCOT_PHONE_NUMBER: /[0-9]{10}/,
    AUTH_NUMBER: /[0-9]{6}/,
    ALPHABET_ONLY: /^[a-zA-Z ]*$/,
    NAME_WITH_SPECIAL_CHARS: /^[a-zA-Z\s\u00C0-\u00FF\-!@#$%^&*()_+=[\]{};':"|<>/?~`]*$/,
    ALPHANUMERIC_WITH_SPECIALCHARS: /^[a-zA-Z0-9.\-,!@#$%^&*()_+=[\]{};':"\\|<>/?~`]+$/i
  },
  IMAGESURL: {
    LOGO: "https://res.cloudinary.com/zudu/image/upload/v1646375553/Young-scot/Project-images/youngscot-logo-png.png",
    LOGO_SMALL:
      "https://res.cloudinary.com/zudu/image/upload/v1646375677/Young-scot/Project-images/youngscot-logo-small.png",
    BALLET_BOX:
      "https://res.cloudinary.com/zudu/image/upload/v1646294989/Young-scot/Project-images/Ballet.svg",
    DUMMY_PROFILE:
      'https://res.cloudinary.com/zudu/image/upload/v1646642342/Young-scot/Project-images/dummyProfile.svg'
  },
};
export default Constant;
